@import 'styles/variables.scss';

.servicesDropdown {
  background: $white;
  padding: 7px 0;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px,
  rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
  rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
}

.content {
  width: 204px;
  padding: 0 16px 13px;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: $font;
  color: $mild-grey;
  font-size: 12px;
  text-transform: uppercase;
  user-select: none;
}

.servicesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 11px;
}
