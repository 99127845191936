.servicesButton {
  padding: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    opacity: 80%;
  }
}
