@import './variables.scss';

@mixin rtl {
  [dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  [dir='ltr'] & {
    @content;
  }
}

@mixin tabletScreensUp {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin container {
  max-width: $container-max-width;
  margin: 0 30px;

  @media (min-width: 1200px) {
    margin: 0 auto;
  }
}

@mixin registerInput {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

@mixin stepDot {
  width: 7px;
  height: 7px;
  border: 1px solid hsla(204, 31%, 34%, 0.12);
  border-radius: 50%;
  margin: 0 8px;
  padding: 3px;
  cursor: pointer;
  position: relative;
  z-index: 80;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: hsla(204, 31%, 34%, 0.12);
    border-radius: 100%;
  }

  &:focus {
    outline: none;
  }

  &.active {
    border-color: hsl(203, 65%, 84%);
    background: transparent;
    width: 16px;
    height: 16px;

    &::before {
      width: 6px;
      height: 6px;
      background: $dimmed-blue-3;
    }
  }
}

@mixin radioButton {
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    display: none;
  }

  [type='radio'] + label {
    position: relative;
    cursor: pointer;
    line-break: anywhere;
    display: block;
    width: fit-content;

    @include ltr {
      padding-left: 25px;
    }
    @include rtl {
      padding-right: 25px;
    }
  }

  [type='radio'] + label:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border-radius: 100%;
    transition: all 0.2s linear;

    @include ltr {
      left: 0;
    }
    @include rtl {
      right: 0;
    }
  }

  [type='radio']:checked + label:before {
    border: 4px solid $color-primary-1;
    background: $white;
  }
  [type='radio']:not(:checked) + label:before {
    border: 1px solid $white;
    background: transparent;
  }
}
