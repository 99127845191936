$font: 'DINNextLTArabic';

$thin-font-weight: 300;
$body-font-weight: 400;
$heading-font-weight: 500;

$body-font-size: 15px;

$container-max-width: 1140px;
$wide-section-max-width: 1120px;
$box-section-max-width: 800px;
$spacing-md: 20px;
$spacing-lg: 40px;

$box-section-padding: 50px 30px;
$box-section-padding-desktop: 35px 80px;
$box-section-border-radius: 20px;

$color-primary-light-1: hsl(259, 81%, 88%);
$color-primary: hsl(275, 45%, 48%);
$color-primary-1: hsl(274, 60%, 49%);
$color-primary-2: hsl(274, 68%, 28%);

$color-secondary: hsl(210, 53%, 93%);
$color-secondary-1: hsl(210, 15%, 71%);
$color-secondary-2: hsl(220, 29%, 12%);

$negative: hsl(4, 90%, 58%);
$positive: hsl(85, 54%, 51%);
$warning: hsl(38, 100%, 50%);

$dimmed-blue-1: hsl(202, 25%, 73%);
$dimmed-blue-2: hsl(204, 31%, 34%);
$dimmed-blue-3: hsl(203, 67%, 20%);

$text-color-primary: hsl(345, 6%, 13%);
$text-color-secondary: hsl(225, 2%, 44%);

$black: hsl(0, 0%, 0%);
$gray-30: hsl(0, 0%, 30%);
$gray-85: hsl(0, 0%, 85%);
$gray-98: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$navigation-bar-height: 40px;
$navigation-bar-bg: $black;
$navigation-bar-content: hsl(0, 0%, 100%);

$light-blue: #e4edf7;
$light-purple: #932acf;
$dark-grey: #414042;
$dark-blue: #31405d7f;
$mild-grey: #6d6e71;
$dark-white: #f1f1f2;
$light-grey: #e6e7e8;
$very-dark-blue: #141c28;
$sky: #b9c8d9;
$max-content-width: 1390px;
$max-footer-width: 1390px;
$font-abeezee: "ABeeZee";
