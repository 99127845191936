@import './fonts.scss';
@import './variables.scss';
@import './mixins.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font;
  font-weight: $body-font-weight;
  font-size: $body-font-size;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.15 linear;
  font-weight: inherit;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  transition: all 0.15s linear;
  font-family: $font;
  font-weight: $heading-font-weight;
  font-size: $body-font-size;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.Toastify__toast {
  @include rtl {
    direction: rtl;
  }
}

.field {
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  transition: all 0.1s;

  &:focus-within {
    box-shadow: 0 0 0 1px #dadada;
  }

  &:hover {
    border-color: #dadada;
  }
}
