@import 'styles/variables.scss';

.portal {
  position: fixed;
  background: $dark-blue;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
}

.content {
  position: absolute;
  display: flex;
}
