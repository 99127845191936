@import 'styles/variables.scss';

.mobileMenu {
  background: $white;
  height: 100vh;
  position: sticky;
  z-index: 99;
  top: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-inline: 18px;
}

.logo {
  width: 47px;
  height: 22px;
}

.close {
  padding: 0;
  display: flex;

  &:hover {
    opacity: 70%;
  }
}

.scroll {
  overflow-y: auto;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-block-start: 40px;
  margin-block-end: 60px;
  margin-inline-start: 40px;
}

.servicesButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-inline-start: 20px;
}

.expandIcon {
  display: flex;
  margin-block-start: 6px;
}

.servicesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 180px;
  margin-inline-start: 12px;
  font-size: 16px;
  overflow: hidden;
  height: 0;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &.expand {
    height: 180px;
  }
}

.link {
  font-family: $font;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: $black;
  letter-spacing: -1.2px;
  border-radius: 6px;
  transition: all 0.2s linear;
  padding: 8px 12px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $light-purple;
    text-decoration-thickness: 2px;
    text-underline-offset: 16px;
  }
}

.services {
  border-top: 1px solid $light-grey;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.servicesAccordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 12px;
  width: 100%;
  cursor: pointer;
}

.servicesTitle {
  display: flex;
  gap: 12px;
  line-height: 27px;
  align-items: center;
  font-size: 16px;
}

.rotate {
  transform: rotate(180deg);
  transition: all 200ms;
}

.activeLink {
  text-decoration: underline;
  text-decoration-color: $light-purple;
  text-decoration-thickness: 2px;
  text-underline-offset: 16px;
}
