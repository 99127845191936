@import 'styles/variables.scss';

.header {
  height: 60px;
  background: $white;
  padding: 0 17px;
  top: 0;
  position: sticky;
  z-index: 99;

  @media (min-width: 840px) {
    height: 80px;
    padding: 0;
  }
}

.headerWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 100%;

  @media (min-width: 840px) {
    height: 58px;
    width: unset;
  }
}

.headerHap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  @media (min-width: 840px) {
    width: unset;
  }
}

.servicesButton {
  margin-inline-start: 16px;
  margin-block-end: 2px;
  display: none;
  border-radius: 5px;
  transition: all 0.2s linear;

  &:hover {
    background-color: rgba($light-purple, 0.16);
  }

  @media (min-width: 840px) {
    display: flex;
  }
}

.hamburgerButton {
  margin-inline-end: 1px;
  display: flex;
  padding: 8px;

  &:hover {
    opacity: 70%;
  }

  @media (min-width: 840px) {
    display: none;
  }
}

.divider {
  width: 1px;
  height: 100%;
  background: $light-grey;
  display: none;
  margin-inline-start: 16px;
  margin-inline-end: 29px;


  @media (min-width: 840px) {
    display: block;
  }
}

.logo {
  width: 47px;
  height: 22px;

  @media (min-width: 840px) {
    width: 64px;
    height: 30px;
  }
}

.links {
  display: none;
  gap: 48px;
  margin-inline-start: 77px;

  @media (min-width: 840px) {
    display: flex;
  }
}

.link {
  font-size: 16px;
  color: $dark-grey;
  border-radius: 5px;
  transition: all 0.2s linear;
  padding: 5px 10px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $light-purple;
    text-decoration-thickness: 2px;
    text-underline-offset: 16px;
  }
}

.activeLink {
  text-decoration: underline;
  text-decoration-color: $light-purple;
  text-decoration-thickness: 2px;
  text-underline-offset: 16px;
}
