@import 'styles/variables.scss';

.servicesItem {
  background: $dark-white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 84px;
  height: 84px;

  .servicesImg {
    height: 90px;
  }

  &:hover {
    opacity: 70%;
  }

  @media (min-width: 840px) {
    width: 80px;
    height: 80px;

    .servicesImg {
      height: 80px;
    }
  }
}

.wrapper {
  position: relative;
  width: 50px;
  height: 50px;
}
