@font-face {
  font-family: DINNextLTPro;
  src: url(../assets/fonts/DINNextLTPro/DINNextLTPro-Medium.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTPro/DINNextLTPro-Medium.woff) format('woff'),
    url(../assets/fonts/DINNextLTPro/DINNextLTPro-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: DINNextLTPro;
  src: url(../assets/fonts/DINNextLTPro/DINNextLTPro-Bold.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTPro/DINNextLTPro-Bold.woff) format('woff'),
    url(../assets/fonts/DINNextLTPro/DINNextLTPro-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: DINNextLTArabic;
  src: url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Light.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Light.woff) format('woff'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: DINNextLTArabic;
  src: url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Regular.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Regular.woff) format('woff'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: DINNextLTArabic;
  src: url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Medium.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Medium.woff) format('woff'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: DINNextLTArabic;
  src: url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Bold.woff2) format('woff2'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Bold.woff) format('woff'),
    url(../assets/fonts/DINNextLTArabic/DINNextLTArabic-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: ABeeZee;
  src: url(../assets/fonts/ABeeZee/ABeeZee-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
